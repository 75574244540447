import React from 'react'
import '../Earning/Earning.css'
// import line from '../../Assets/images/'
import gamesimg from '../../Assets/images/exp10.webp'




const Earning = () => {
  return (
    <section className="about-section earningsec style-three pt-50 pb-50" id="about">
    <div className="container">
 
      <div className="row align-items-center aboutIcons membership-title-2">
      <div className="col-lg-8 order-lg-1 order-sm-0">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4 earningtxt">
                                <div className="vx_section-title text-left mb-60 ">
                                 
                                </div>
                                <h2 className='title mb-4'>Allow <span className='spn'>VitnixX Ai</span>  to Assist You in Earning a Profit!</h2>
                                <p>In an ever-changing market, forecasting price movements becomes a challenge. That's where our ally steps in, automatically placing Buy and Sell orders to capitalize on any market fluctuations, whether on your exchange or others.

                                </p>
                                <p>
                                   

                                If required, establish a connection through API access, then patiently await performance data. Once the data becomes available, evaluate the effectiveness of these strategies through demo testing before committing real funds.


                                </p>

                            
                            </div>
                        </div>
                        <div className="col-lg-4 order-lg-0 order-sm-1 text-center">


                            <div className="services__wrapper">
                                <img className='mb-4 erngimg' src={gamesimg} width="500" height="500" alt="Services img" />


                            </div>
                        </div>
      </div>

    </div>
  </section>
  
  )
}

export default Earning