import React from 'react'

import gamesimg from '../../Assets/images/Globe.webp'

import '../Investments/Investments.css'

const Investments = () => {
 

    return (
        <>
      

            <section  className="services-area vx_ecosystem-main investmentssec section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row align-items-center">
                        
                        
                        <div className="col-lg-5 order-lg-1">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                  
                                </div>
                                <h2 className='title mb-3'>Your Investments,<span className='spn'>Your Goals,</span> Our Customized Approach

                                </h2>
                                <p>If you are finding your profit potential, trust our forex account management for superior ROI.
                                </p>
                                <p>
                                   
With our extensive experience in assisting clients' growth, we've honed our market analysis skills to keep you in sync with the latest trends, elevating your account profits to new heights.

                                </p>

                              
                            </div>
                        </div>
                        <div className="col-lg-7 order-lg-0 text-center">


                            <div className="services__wrapper">
                                <img className='mb-4 ' src={gamesimg} width="500" height="500" alt="Services img" />


                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
        </>
    )
}

export default Investments