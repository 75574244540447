import React from 'react'

import gamesimg from '../../Assets/images/platform.png'

import '../Platform/Platform.css'

const Platform = () => {


    return (
        <>
           
            <section  className="services-area vx_ecosystem-main platformsec section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row align-items-center">


                        <div className="col-lg-6 order-lg-0">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                
                                </div>
                                <h2 className='title mb-3'>All <span className='spn'> Trades</span> Are Carried Out on the Platform
                                </h2>
                                <p>Stands as a sophisticated and user-friendly platform, providing traders with a comprehensive tool to oversee every facet of their financial portfolios.
                                </p>
                                <p>
                                    It incorporates analytical tools, indicators, and market depth data for trading stocks, indices, or forex currency pairs across global markets. Notably, the US Stocks Portfolio Monitor delivers real-time investment updates 24/5, ensuring you stay informed about your portfolio.

                                </p>

                              
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-1 text-center">


                            <div className="services__wrapper">
                                <img className='mb-4 ' src={gamesimg} width="500" height="500" alt="Services img" />


                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
        </>
    )
}

export default Platform