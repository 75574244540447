import React from "react";
import { useRefs } from '../../Pages/RefsContext'
import "../Strategies/Strategies.css"


const Stratergies = () => {
  const { strategiesRef } = useRefs();
  return (
    <>
      <section ref={strategiesRef} className="strategies" id="strategies">
        <div className="container">
          <div className="row">
            <div className="col-12 lftsec col-lg-4">
              <div className="left-text vx_section-title ">
                <h2 className="title">
                Welcome the Forex  <br />
                  <span className="spn">Trading Robot </span>        with the Highest Profit Potential to Manage Your Trades
                
                </h2>
                <div className="subtext">
                A VitnixX Ai robot assists traders who want to use automated trading methods to help with the repetitive elements and technical analysis of forex trading.
                </div>
                {/* <div className="mbview">
                  <img src={mbview} alt="" />
                </div> */}
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="right-content">
                <div className="scrollable-content">
                <div className="row ">
                  <div className="col-12 col-lg-6">
                    <div className="outwrapbox">
                      <div className="strategybox">
                        <div className="layerbox">
                        
                          <div className="icon-content">
                            <h3>Strategies</h3>
                            <p>We closely collaborate with your trading account, identifying the most fitting strategies tailored to your account, and seamlessly implement these strategies into your trading portfolio. </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="outwrapbox outwrapbox1">
                      <div className="strategybox">
                        <div className="layerbox">
                        
                          <div className="icon-content">
                            <h3>Forex Account Management
</h3>
                            <p>With over a decade of expertise in the forex industry, we have gained profound insights into efficiently managing our clients' forex trading accounts.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="outwrapbox outwrapbox2">
                      <div className="strategybox">
                        <div className="layerbox">
                        
                          <div className="icon-content">
                            <h3>Algo Management</h3>
                            <p>Beyond the initial setup of Algo software in your trading account, we go the extra mile by regularly updating and fine-tuning its settings, allowing you to sit back and savor your profits.
</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="outwrapbox outwrapbox3">
                      <div className="strategybox">
                        <div className="layerbox">
                         
                          <div className="icon-content">
                            <h3>Transparent</h3>
                            <p>At VitnixX Ai, transparency is the cornerstone of our operations. We are committed to furnishing our clients with specific and transparent trade information on both MT4 and MT5 platforms. Trade with confidence, secure in the knowledge that your investments are managed with the utmost transparency.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="outwrapbox outwrapbox4">
                      <div className="strategybox">
                        <div className="layerbox">
                        
                          <div className="icon-content">
                            <h3>Safe</h3>
                            <p>Securing your investments remains our foremost concern. Our firm implements stringent security measures to guarantee the protection of both your funds and confidential data. Rest easy while trading on the MT4 and MT5 platforms, confident in the knowledge that we have implemented cutting-edge security protocols to defend your assets.
</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="outwrapbox outwrapbox5">
                      <div className="strategybox">
                        <div className="layerbox">
                       
                          <div className="icon-content">
                            <h3>Flexible
</h3>
                            <p>"We recognize that each trader possesses distinct requirements and preferences. This is why our MT4 and MT5 trading platforms provide an exceptional level of adaptability. With an extensive array of customizable features, advanced order types, and a diverse selection of trading instruments, you can personalize your trading journey to align perfectly with your specific objectives and strategies."
</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="outwrapbox outwrapbox6">
                      <div className="strategybox">
                        <div className="layerbox">
                          
                          <div className="icon-content">
                            <h3>Profitable
</h3>
                            <p>We are dedicated to assisting our clients in attaining profitable trading results. Utilizing the MT4 and MT5 platforms grants you access to the essential tools and resources required to seize market opportunities. Leveraging our expert analysis, up-to-the-minute data, and favorable trading conditions, you can optimize your profit potential and realize your financial aspirations.

</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stratergies;
