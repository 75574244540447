import React from 'react'
import ReactPlayer from 'react-player';
// import banneranim2 from '../../Assets/images/banneranim-2.webp'

import gamesimg from '../../Assets/images/robot1.mp4'
import { useRefs } from "../../Pages/RefsContext";

const Aboutus = () => {


    return (
        <>

            <section className="services-area aboutussec vx_ecosystem-main section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 order-lg-1">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                    {/* <span className="vx_sub-title vx_animate-text spn"><span>About Us</span></span> */}
                                </div>
                                <h2 className='title mb-3'>Welcome the Forex <span className='spn'>Trading Robot </span> with the Highest Profit Potential to Manage Your Trades</h2>
                                <p>A VitnixX Ai robot assists traders who want to use automated trading methods to help with the repetitive elements and technical analysis of forex trading.
                                </p>
                                {/* <a href="https://docs.vitnixx.com/" className="vx_border-button mt-3"><span>Whitepaper</span></a> */}
                            </div>
                        </div>
                        <div className="col-lg-7 order-lg-0 text-center">


                            <div className="services__wrapper   aboutvid position-relative">
                                <ReactPlayer
                                className=""
                                    url={gamesimg} 
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Aboutus