import React from 'react'
// import banneranim2 from '../../Assets/images/banneranim-2.webp'
import nftaboutsection from '../../Assets/images/bot.webp'
import '../Discover/Discover.css'

import { useRefs } from '../../Pages/RefsContext'
const Discover = () => {
    const { gameRef } = useRefs();
    return (
        <>
         
            <section ref={gameRef} className="services-area vx_ecosystem-main section-pt-50 discoversec section-pb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                {/* <div className="vx_section-title text-left mb-60">
                                    <span className="vx_sub-title vx_animate-text"><span>Games</span></span>
                                </div> */}
                                <h2 className='title mb-3'>Discover <span className='spn'> VitnixX Ai: </span> Your Ultimate Destination for All Your Currency Trading Requirements</h2>
                                <p>Instantly implement winning strategies onto charts with a simple click, without the need for any additional concerns, such as technical analysis or expertise in the field! </p>
                                <p>It manages everything, including setting up positions across global markets using automated algorithms, allowing you to focus on reaping the rewards of your profits.</p>
                              
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-0 text-center">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_services-images">
                            <img src={nftaboutsection}  width={500} height={300} alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
   
        </>
    )
}

export default Discover