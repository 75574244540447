import React from 'react'
import { useRefs } from '../../Pages/RefsContext'
import icon1 from '../../Assets/images/activation-bonus.png'
import icon2 from '../../Assets/images/rank-bonus.png'
import icon3 from '../../Assets/images/royalty-pool.png'
import icon4 from '../../Assets/images/rank-profit-sharing.png'
import icon5 from '../../Assets/images/brokerage.png'
import icon6 from '../../Assets/images/profit-sharing.png'
import "../Keyfeatures/Keyfeatures.css"

const KeyFeatures = () => {
    const { featuresRef } = useRefs();
    return (
        <>
            <section ref={featuresRef} className="keyfeature" id='keyfeature'>
                <div className="container">
                    <div
                        data-aos="fade-up"
                        data-aos-duration={1500}
                        className="dreamit-section-title two text-center "
                    >
                        <div className="vx_section-title text-left mb-60">
                            <span className="vx_sub-title vx_animate-text "><span className='spn'>Income</span></span>
                        </div>
                        <div className="dreamit-section-main-title">
                            <h2>Passive <span className='spn'> Income</span></h2>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-12 col-md-6 col-xl-4">

                            <div className="keyboxwrap">
                                <div className="keybox">
                                    <div className="icon-box">
                                        <div className="keyicon">
                                            <img height={120} width={120} src={icon1} />
                                        </div>
                                    </div>
                                    <div className="key-body">
                                        <h3 className='title'>Activation Bonus</h3>
                                        <p>
                                            Get an Activation Bonus by inviting your friends to join using your platform referral link.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="keyboxwrap">
                                <div className="keybox">
                                    <div className="icon-box">
                                        <div className="keyicon">
                                            <img height={130} width={130} src={icon2} />
                                        </div>
                                    </div>
                                    <div className="key-body">
                                        <h3 className='title'>Rank Bonus
                                        </h3>
                                        <p>
                                            Bonuses reward upon getting a higher rank
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="keyboxwrap">
                                <div className="keybox">
                                    <div className="icon-box">
                                        <div className="keyicon">
                                            <img height={130} width={130} src={icon3} />
                                        </div>
                                    </div>
                                    <div className="key-body">
                                        <h3 className='title'>Royalty Pool  </h3>
                                        <p>
                                            Increase your earnings through the Royalty Pool, a worldwide profit-sharing initiative crafted to boost your income potential.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6  col-xl-4">
                            <div className="keyboxwrap">
                                <div className="keybox">
                                    <div className="icon-box">
                                        <div className="keyicon">
                                            <img height={64} width={64} src={icon4} />
                                        </div>
                                    </div>
                                    <div className="key-body">
                                        <h3 className='title'>Rank Profit Sharing
                                        </h3>
                                        <p>
                                            Receive profit-sharing based on your current rank in the system.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="keyboxwrap">
                                <div className="keybox">
                                    <div className="icon-box">
                                        <div className="keyicon">
                                            <img height={130} width={130} src={icon5} />
                                        </div>
                                    </div>
                                    <div className="key-body">
                                        <h3 className='title'>Brokerage
                                        </h3>
                                        <p>
                                            Experience unprecedented per-lot brokerage earnings like never seen before.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="keyboxwrap">
                                <div className="keybox">
                                    <div className="icon-box">
                                        <div className="keyicon">
                                            <img height={130} width={130} src={icon6} />
                                        </div>
                                    </div>
                                    <div className="key-body">
                                        <h3 className='title'>Profit Sharing
                                        </h3>
                                        <p>
                                            Continue to reap the benefits of our exclusive profit sharing feature, exclusively presented for you.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default KeyFeatures