import React from 'react'
import Banner from '../../Components/Banner/Banner'

import Faqs from '../../Components/Faqs/Faqs'

import Aboutus from '../../Components/Aboutus/Aboutus'

import KeyFeatures from '../../Components/Keyfeatures/Keyfeatures'
import Stratergies from '../../Components/Strategies/Strategies'
import Aboutvtnx from '../../Components/Aboutvtnx/Aboutvtnx'
import Trading from '../../Components/Trading/Trading'
import Investments from '../../Components/Investments/Investments'
import Advantages from '../../Components/Advantages/Advantages'
import Discover from '../../Components/Discover/Discover'
import Platform from '../../Components/Platform/Platform'
import Earning from '../../Components/Earning/Earning'
import Robot from '../../Components/Robot/Robot'

const Home = () => {
    return (
        <>
        
            <Banner />
            <Aboutus />
            <Aboutvtnx />
            <Trading />
            <Investments />
            <KeyFeatures />
            <Stratergies />
            <Advantages />
       
            <Discover />
            <Platform />
            <Earning />
            <Robot />
          
            <Faqs />
        </>
    )
}

export default Home