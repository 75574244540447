import React from 'react'

import gamesimg from '../../Assets/images/vitnixxailogo.gif'

import '../Robot/Robot.css'

const Robot = () => {
  

    return (
        <>
        
            <section  className="services-area vx_ecosystem-main robotsec section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row align-items-center">


                        <div className="col-lg-7 order-lg-0">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                   
                                </div>
                                <h2 className='title mb-3'>The First Trading Robot with <span className='spn'>Unparalleled Features </span>  and Advantages in the World of Trading Robots.
                                </h2>
                                <p className='mt-3'>The primary benefit of employing a Forex robot lies in its ability to carry out trades autonomously, eliminating the need for human involvement. 
                                </p>
                                <p>
                                Say goodbye to waiting on the phone or queuing at your broker's office! With this technology, you can access many investment opportunities, encompassing short-selling stocks, bonds, and stock options,
                                 effectively minimizing risks associated with potential downturns. Additionally, you can rest assured that safeguards like stop orders are in place to mitigate potential losses.

                                </p>
                                <p>And the most remarkable aspect? You don't require a prior understanding of how this operates.
 
                                </p>

                             
                            </div>
                        </div>
                        <div className="col-lg-5 order-lg-1 text-center">


                            <div className="services__wrapper">
                                <img className='mb-4 ' src={gamesimg} width="500" height="500" alt="Services img" />


                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
        </>
    )
}

export default Robot