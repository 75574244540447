import React from 'react'
import { useRefs } from '../../Pages/RefsContext'
import "../Banner/Banner.css"
import bnr from "../../Assets/images/bnr.png"
const Banner = () => {
  const { homeRef } = useRefs();
  return (
    <>

      <section ref={homeRef} className="topmainsecai">
        <div className="container">
          <div className="infobox">
            <img src={bnr} className="topmainvideo">

            </img>
            <div className="infotext">
              <h1><span className='spn'>VitnixX-Ai</span> - Empowering The Future Of Forex Trading</h1>
              <p>Revolutionize Your Forex Journey with Our AI-Powered Trading Robot</p>
              <div data-aos="fade-up" data-aos-duration="3000" className="vx_button  flex-wrap">
                <a href="https://ai.vitnixx.com/register" className="vx_border-button" target=''><span>Register Now</span></a>

                <a href="https://ai.vitnixx.com/login" className="vx_border-button" target=''><span>Login Now</span></a>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Banner