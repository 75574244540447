import React from 'react';
import instagram from '../../Assets/images/instagram3.png'
import facebook from '../../Assets/images/facebook.png'
import telegram from '../../Assets/images/telegram.png'
import backtotop from '../../Assets/images/icons/top.webp'

const Footer = () => {
    const scrollToTop = (event) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            {/* Footer Section Start */}
            <footer className='vx_footer'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="vx_footer-copyright-text">
                                <p>Copyright © 2024 - All Rights Reserved By <span>VitnixX Ai</span></p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="vx_footer-card text-center text-md-end">
                                <div className="vx_footer-social">
                                    <a href="https://www.instagram.com/vitnixx.ai" target="_blank" rel="noreferrer" title="Instagram"><img src={instagram} width="22" height="22" className='instagram' alt="instagram icon" /></a>

                                    <a href="https://www.facebook.com/vitnixxai" target="_blank" rel="noreferrer" title="Medium"><img src={facebook} width="22" height="22" alt="medium icon" /></a>
                                    <a href=" https://t.me/vitnixxai" target="_blank" rel="noreferrer" title="Medium"><img src={telegram} width="22" height="22" alt="medium icon" /></a>







                                    <a href="#!" onClick={scrollToTop} target="_blank" className='backtotop' rel="noreferrer">
                                        <img src={backtotop} width="30" height="30" alt="backtotop icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer