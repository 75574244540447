import React from 'react'
import robot2 from '../../Assets/images/robot2.mp4'
import ReactPlayer from 'react-player';


import gamesimg from '../../Assets/images/robot-character-with-mobile-removebg-preview.png'
import { useRefs } from "../../Pages/RefsContext";
import '../Trading/Trading.css'

const Trading = () => {


    return (
        <>
        
            <section  className="services-area vx_ecosystem-main tradsec section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row align-items-center">
                        
                        
                        <div className="col-lg-5 order-lg-0">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                   
                                </div>
                                <h2 className='title mb-3'>Embark on <span className='spn'>Robot</span> Forex Trading with Us
                                </h2>
                                <p>A VitnixX Ai robot assists traders who want to use automated trading methods to help with the repetitive elements and technical analysis of forex trading.
                                </p>
                                <p>
                                    These AI-driven tools leverage machine learning to meticulously monitor the financial markets, specifying promising signals with profit potential. Once identified, the automated software promptly transmits these optimal signals or momentum to integrated brokers through APIs, ensuring swift execution of your trades.
                                </p>

                            
                            </div>
                        </div>
                        <div className="col-lg-7 order-lg-1 text-center">


                        <div className="services__wrapper aboutusimg position-relative">
                                <ReactPlayer
                                    url={robot2} 
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Aboutus Section End */}
        </>
    )
}

export default Trading