import React, { useState } from "react";
import { useRefs } from '../../Pages/RefsContext'


const Faqs = () => {
    const { faqRef } = useRefs();

  return (
    <>
            <section ref={faqRef} className="services-area faqsec vx_ecosystem-main section-pt-50 section-pb-50">

      <div className="container my-5">
        <div className="text-center mb-5">
        <div
                data-aos="fade-up"
                data-aos-duration="3000"
                className="vx_section-title text-center mb-60"
              >
                <span className="vx_sub-title vx_animate-text">
                  <span>Questions</span>
                </span>
              </div>
          <h1 className="text-capitalize   font-weight-bold">
            Frequently <span className="spn">Asked </span> Questions</h1>
        </div>

    
         <div class="faq__body">
       <details aria-expanded="false" class="faq__panel" >
           <summary class="faq__label"> What is the deposit fee to sign up for VitnixX Ai?</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">The VitnixX Ai deposit fee starts at $100 and goes above $2500. You can choose as per your choice.  </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label">Where Can I Ask For a Refund?
</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">You can get a Min Profit Guarantee refund anytime per the selected plan offer.</p>
           </div>
       </details>
    
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label"> What Is The Minimum Returns I Can Get Using VitnixX Ai?
</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">The returns on VitnixX Ai are not fixed.


           </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label"> When Will Trading Start?
</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">Within 24 to 72 hours after the investment, trading will begin.


           </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label">When Can I Withdraw My Earnings?
</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">
           The commission can be withdrawn immediately after it has been credited to the wallet, and a minimum withdrawal is $10.
 
           </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label"> How Much Time Will It Take To Process Withdraw?
</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">
           Withdrawal of the invested amount can be made at any time, fully or partially, and the process will take between 24 and 72 hours.

           </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label">Which Wallet Should I Use?
</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">
           The Polygon Chain integrates with various wallets, ensuring compatibility with your preferred choice. Once connected, simply navigate to your profile and select the wallet update button. Whether you prefer Trust Wallet or Metamask, the process is user-friendly and adaptable to your chosen wallet."


           </p>
           </div>
       </details>
       <details aria-expanded="false" class="faq__panel">
           <summary class="faq__label"> What If I Don't Have Matic To Pay Gas Fee?

</summary>
           <div class="faq__panel-body">
           <p class="faq__panel-answer">
           To facilitate transactions on the Polygon Chain, it's essential to maintain a minimum balance to cover gas fees. You can quickly transfer Matic Balance from any compatible wallet to ensure a seamless and hassle-free transaction experience.


           </p>
           </div>
       </details>
   </div>
      </div>
      </section>

     
    </>
  );
};

export default Faqs;
