// RefsContext.jsx

import { createContext, useContext, useRef } from 'react';

export const RefsContext = createContext();

export const useRefs = () => useContext(RefsContext);

export const RefsProvider = ({ children }) => {
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const featuresRef = useRef(null);
    const servicesRef = useRef(null);

    const advantagesRef = useRef(null);
    const strategiesRef = useRef(null);
    const faqRef = useRef(null);
    // Add more refs here for each section

    const value = {
        homeRef,
        aboutRef,
        featuresRef,
        servicesRef,
       advantagesRef,
        strategiesRef,
        faqRef,
        // ... additional refs
    };

    return (
        <RefsContext.Provider value={value}>
            {children}
        </RefsContext.Provider>
    );
};
