import React from 'react'

import gamesimg from '../../Assets/images/robot-character-with-mobile-removebg-preview.png'
import { useRefs } from "../../Pages/RefsContext";
import '../Advantages/Advantages.css'
import lowrisk from '../../Assets/images/Trading Resolution.webp'
import analysis from '../../Assets/images/Cutting Edge Trading (1).webp'
import maintain from '../../Assets/images/Multitask performance.webp'
import accuracy from '../../Assets/images/Competitive Trading.webp'
import generation from '../../Assets/images/Robotic trading.webp'
import balance from '../../Assets/images/No external Influence.webp'
import robot3 from '../../Assets/images/robot3.mp4'

import ReactPlayer from 'react-player';


const Advantages = () => {
    const { advantagesref } = useRefs();

    return (
        <>
         
            <section ref={advantagesref} className="services-area vx_ecosystem-main advsec section-pt-50 section-pb-50">
                <div className="container">
                    <div className="row align-items-center">
                        
                        
                        <div className="col-lg-7 order-lg-0">
                            <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                 
                                </div>
                                <h2 className='title mb-3'>Why  <span className='spn'>VitnixX-Ai</span> ?
                                </h2>
                               

                             
                            </div>
                            <div className="row align-items-center aboutIcons advsectext ">
                            <div className="col-lg-6 col-md-6">
          <div className="about-shape d-none d-md-block h-100">
         
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb advimg">
              <img
                src={lowrisk}
                width={50}
                height={50}
                alt="No Risk"
              />
            </div>
            <div className="about-content">
                <h1>Trading Resolution</h1>
              <p>Tailored Trading Solutions for Both Seasoned Day Traders and Fresh Market Entrants.</p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={analysis}
                width={50}
                height={50}
                alt="Analysis"
              />
            </div>
            <div className="about-content">
            <h1>Cutting Edge Trading</h1>
              <p>We aspire to empower you with the assurance to excel in online forex trading.</p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={maintain}
                width={50}
                height={50}
                alt="Maintain"
              />
            </div>
            <div className="about-content">
            <h1>Multitask Performance
</h1>
              <p>Capable of driving multiple programs simultaneously.

</p>
            </div>
          </div>
       
        </div>
        <div className="col-lg-6 col-md-6">
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={accuracy}
                width={50}
                height={50}
                alt="Accuracy"
              />
            </div>
            <div className="about-content">
            <h1>Competitive Pricing</h1>
              <p>Offering Ordinary Traders Competitive and Cost-Efficient Pricing.

</p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={generation}
                width={50}
                height={50}
                alt="Generation"
              />
            </div>
            <div className="about-content">
            <h1>Robotic Trading</h1>
              <p>Robotic Execution Powers Automated Trading.

</p>
            </div>
          </div>
         
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={balance}
                width={50}
                height={50}
                alt="Balance"
              />
            </div>
            <div className="about-content">
            <h1>No External Influences
</h1>
              <p>
              Eliminating Emotional Influence from Trading Decisions.


              </p>
            </div>
          </div>
        </div>
        </div>
                        </div>
                        <div className="col-lg-5 order-lg-1 text-center">


                        <div className="services__wrapper aboutusimg position-relative">
                                <ReactPlayer
                                    url={robot3} 
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
        </>
    )
}

export default Advantages