import React from 'react'
import '../Aboutvtnx/Aboutvtnx.css'
import { useRefs } from "../../Pages/RefsContext";
import lowrisk from '../../Assets/images/Blend.png'
import analysis from '../../Assets/images/Round the clock.png'
import management from '../../Assets/images/Exceptional risk.png'
import maintain from '../../Assets/images/Preserve balance.png'
import accuracy from '../../Assets/images/Accuracy.png'
import generation from '../../Assets/images/Seamless Automation.png'
import multiple from '../../Assets/images/Numerous orders.png'
import balance from '../../Assets/images/Greed or Fear.png'



const Aboutvtnx = () => {
  const { aboutRef } = useRefs();
  return (
    <section ref={aboutRef}  className="about-section style-three pt-50 pb-50 aboutvtnx" id="about">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="dreamit-section-title two text-center "
          >
            <div className="vx_section-title text-left mb-60">
                <span className="vx_sub-title vx_animate-text "><span className='spn'>About VitnixXAi</span></span>
                </div>
            <div className="dreamit-section-main-title">
              <h2>Let's Conquer <span className='spn'>New Horizons</span>  and Achieve Greatness</h2>
            </div>
            <div className="dreamit-section-content-text">
              <p>A Tool to Empower Forex Traders</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center aboutIcons membership-title-2">
        <div className="col-lg-6 col-md-6">
          <div className="about-shape d-none d-md-block h-100">
          {/* <div className="nfts-box-line"></div> */}
            {/* <img
              className="h-100"
              src={line}
              width={50}
              height={50}
              alt=""
            /> */}
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={lowrisk}
                width={50}
                height={50}
                alt="No Risk"
              />
            </div>
            <div className="about-content">
              <h2>A blend of Diverse Low-Risk Approaches</h2>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={analysis}
                width={50}
                height={50}
                alt="Analysis"
              />
            </div>
            <div className="about-content">
              <h2>Round-the-Clock Market Surveillance and Analysis</h2>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={maintain}
                width={50}
                height={50}
                alt="Maintain"
              />
            </div>
            <div className="about-content">
              <h2>Preserve an Optimal Balance Between Risk and Reward</h2>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={management}
                width={50}
                height={50}
                alt="Management"
              />
            </div>
            <div className="about-content">
              <h2>Exceptional Risk Mitigation Practices</h2>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={accuracy}
                width={50}
                height={50}
                alt="Accuracy"
              />
            </div>
            <div className="about-content">
              <h2>More than 80% Trade Accuracy Exceeding</h2>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={generation}
                width={50}
                height={50}
                alt="Generation"
              />
            </div>
            <div className="about-content">
              <h2>Seamless Automation from Order Generation to Execution</h2>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={multiple}
                width={50}
                height={50}
                alt="Multiple"
              />
            </div>
            <div className="about-content">
              <h2>Efficiently Handle Numerous Orders Simultaneously</h2>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration={1500}
            className="about-single-icon-box d-flex align-items-center"
          >
            <div className="about-icon-thumb">
              <img
                src={balance}
                width={50}
                height={50}
                alt="Balance"
              />
            </div>
            <div className="about-content">
              <h2>
              VitnixX Ai excels with emotional equilibrium, free from greed or fear
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="about-shape-one bounce-animate2  d-none d-md-block">
                  <img src="assets/images/new/round.png" alt="">
              </div> */}
    </div>
  </section>
  
  )
}

export default Aboutvtnx